<template>
  <v-text-field
    :label="label"
    v-model="colorSelect"
    hide-details
    readonly
    onmousedown="return false;"
  >
    <template v-slot:append>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text
            class="pa-0"
            @click="sendColor"
            :ripple="false"
            v-on:keyup.enter="sendColor"
          >
            <v-color-picker
              v-model="colorSelect"
              dot-size="24"
              hide-mode-switch
              mode="hexa"
              show-swatches
              swatches-max-height="200"
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "ColorPickerSelected",
  props: {
    label: {
      type: String,
      default: ""
    },
    defColor: {
      type: String,
      default: "#000000FF"
    }
  },
  data() {
    return {
      active: true,
      colorSelect: this.defColor,
      mask: "!#XXXXXXXX",
      menu: false
    };
  },
  computed: {
    swatchStyle() {
      const { colorSelect, menu } = this;
      return {
        backgroundColor: colorSelect,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        border: "0.2px dashed #0000007a"
      };
    }
  },
  watch: {
    defColor(newValue) {
      if (newValue) {
        this.colorSelect = newValue;
      }
    }
  },
  created() {},
  methods: {
    sendColor() {
      this.$emit("setColorSelected", this.colorSelect);
    },
    cancel() {
      this.colorSelect = this.defColor;
    }
  }
};
</script>
